
/* eslint-disable */
import JigTextField from '@/components/input/JigTextField.vue'
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import GenericError from '@/components/snackbars/GenericError.vue'
import { ColorSet } from '@/components/statistics/constants'
import StatLineChart from '@/components/statistics/StatLineChart.vue'
import { MueConst, ReportConst } from '@/constants'
import JigLock from '@/mixin/JigLock'
import { Tenant } from '@/store/modules/app/types'

import { ChangeJigOwnerRequest, ChangeJigOwnerResult } from '@/store/modules/jig/types'
import { JigActivityChartData, JigSummaryStats, Namespace, ReportRequest, StandardObject } from '@/store/types'
import { ValidationRules } from '@/utils/input-validation'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { mixins } from 'vue-class-component'
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

const Top5 = 5
const LookBackDays = 30
const ComparePreviousPeriod = true

const defaultActivityTrendChart: JigActivityChartData = {
  data: {
    labels: [],
    datasets: [
      {
        label: '',
        backgroundColor: '#f87979',
        data: [],
      },
    ],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
      },
    },
    title: {
      display: true,
      text: 'Most popular Jigs in 30 days',
    },
    legend: {
      display: true,
    },
    tooltips: {
      enabled: true,
    },
  },
}

@Component({
  components: {
    StatLineChart,
    'generic-error': GenericError,
    'jig-text-field': JigTextField,
    'prompt-yes-no': PromptYesNo,
  },
})
export default class CommandCentre extends mixins(JigLock) {
  @State('jigDailyActivityData', { namespace: Namespace.Jig })
  public jigDailyActivityData!: JigSummaryStats[]
  @State('changeJigOwnerResult', { namespace: Namespace.Jig })
  public changeJigOwnerResult!: ChangeJigOwnerResult
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant

  @Action('executeStatisticReport', { namespace: Namespace.Jig })
  public executeStatisticReport: any
  @Action('breakLock', { namespace: Namespace.Jig })
  public breakLock: any
  @Action('changeJigOwner', { namespace: Namespace.Jig }) public changeJigOwner: any
  @Action('loadJigMetadata', { namespace: Namespace.Jig })
  public loadJigMetadata: any

  private isFetchingJigData: boolean = false
  private transferJigFormIsValid: boolean = false
  private isValidJig: boolean = false
  private jigHashIds: string = ''
  private lockedJigHashId: string = ''
  private jigHashIdsSet: Set<string> | null = null
  private jigTransferResults: StandardObject[] = []
  private unlockJigResult: StandardObject = {}
  private transferToTenantID: number = TenantHelpers.InvalidTenantID
  private desiredOwnerEmail: string = ''
  private jigHashIdsWithDelimiterRules!: any
  private jigHashIdRules!: any
  private emailRules!: any
  private numberRules!: any
  private isTransfering: boolean = false
  private isUnlockingJig: boolean = false
  private jigActivityTrendChart: JigActivityChartData = defaultActivityTrendChart

  public $refs!: Vue['$refs'] & {
    transferForm: any
    transferJigsPromptYesNo: PromptYesNo
    unlockJigForm: any
  }

  constructor() {
    super()
  }

  protected async created() {
    this.jigHashIdsWithDelimiterRules = ValidationRules.RequiredJigHashIDs
    this.jigHashIdRules = ValidationRules.RequiredJigHashID
    this.emailRules = ValidationRules.RequiredEmail
    this.numberRules = ValidationRules.RequiredNumber
    this.transferToTenantID = this.myTenant.ID
  }

  private get getJigActivityTrendChart(): JigActivityChartData {
    this.updateJigActivityTrendChart(this.jigDailyActivityData)
    return this.jigActivityTrendChart
  }

  private clearTransferForm() {
    this.resetTransferData()
    this.jigTransferResults.length = 0
  }

  private clearUnlockJigForm() {
    this.$refs.unlockJigForm.reset()
    this.unlockJigResult = {}
  }

  private resetTransferData() {
    this.$refs.transferForm.reset()
    if (this.jigHashIdsSet != null && this.jigHashIdsSet.size) {
      this.jigHashIdsSet.clear()
    }
  }

  // support manual fetch rather than automatic on page opening fetching
  private async fetchJigActivityDataClick() {
    let today = new Date()
    let startDate = new Date()
    startDate.setDate(today.getDate() - 31)
    let endDate = new Date()
    endDate.setDate(today.getDate() - 1)

    const req: ReportRequest = {
      Name: ReportConst.Report.MostPopularJig,
      JigHashId: '',
      Limit: Top5,
      LookBackDays,
      StartDate: startDate.toISOString(),
      EndDate: endDate.toISOString(),
      ComparePreviousPeriod,
    }

    this.isFetchingJigData = true
    await this.executeStatisticReport(req)
    this.isFetchingJigData = false
  }

  private updateJigActivityTrendChart(jigSummaryComparisonStats?: JigSummaryStats[]) {
    if (jigSummaryComparisonStats == null) {
      return
    }

    this.jigActivityTrendChart.data.datasets.length = 0
    this.jigActivityTrendChart.data.labels.length = 0

    let maxTotal = 0
    const labels: string[] = []

    // for each Jig capture a series of date (day) view totals.
    const jigActivitySeriesData: Map<number, number[]> = new Map<number, number[]>()

    // get a unique list of jig names.
    const jigNames: Map<number, string> = new Map<number, string>()

    jigSummaryComparisonStats.forEach(
      (item: {
        id: number
        statisticDate: string
        application: string
        jigId: number
        jigName: string
        activity: string
        total: number
        durationSeconds: number
        duration: string
      }) => {
        maxTotal = Math.max(maxTotal, item.total)
        const sd = new Date(item.statisticDate)
        if (jigActivitySeriesData.has(item.jigId)) {
          const s = jigActivitySeriesData.get(item.jigId)
          if (s) {
            // add another activity counter onto this Jig series.
            s.push(item.total)
          }
        } else {
          jigActivitySeriesData.set(item.jigId, [item.total])
        }

        // keep a list of unique jig names
        if (!jigNames.has(item.jigId)) {
          jigNames.set(item.jigId, item.jigName)
        }

        // create month + date labels
        labels.push(sd.toLocaleString('en-US', { month: 'short' }) + '/' + sd.getDate().toString())
      }
    )

    this.jigActivityTrendChart.data.labels = labels

    // for each jig activity...
    let i = 0
    jigActivitySeriesData.forEach((statisticData: number[], jigId: number) => {
      const jigName = jigNames.get(jigId) || 'Jig name not set'
      this.jigActivityTrendChart.data.datasets.push({
        label: jigName,
        backgroundColor: ColorSet[i % ColorSet.length],
        data: statisticData,
      })
      i++
    })
  }

  private pauseTransfer() {
    this.isTransfering = false
  }

  private async handleTransferJigs() {
    this.jigTransferResults.length = 0
    if (!this.transferJigFormIsValid || this.jigHashIds.length === 0 || this.transferToTenantID === TenantHelpers.InvalidTenantID) {
      return
    }

    this.jigHashIdsSet = new Set(this.jigHashIds.split(/\s*,\s*/g))

    this.$refs.transferJigsPromptYesNo.ShowPrompt()
  }

  private async transferJigs() {
    this.isTransfering = true
    if (this.jigHashIdsSet == null) {
      this.isTransfering = false
      return
    }

    for (const jigHashId of this.jigHashIdsSet) {
      await this.loadJigMetadata({ jigId: jigHashId })

      this.updateLockPayloadJigId(jigHashId)

      const isLockObtained = await this.RequestNewLock()
      if (!isLockObtained) {
        if (this.IsJigLockActive && this.IsJigLockOwnedByOthers()) {
          this.jigTransferResults.push({
            isSuccessful: false,
            id: jigHashId,
            message: `${this.JigLockOwnerName || 'User'} (UID: ${
              this.jigMetadata.UserActivity ? this.jigMetadata.UserActivity.LockedByUID : 'unknown'
            }) is currently editing the Jig. Please ask them to save and close the Jig then try again.`,
          })
        } else {
          this.jigTransferResults.push({ isSuccessful: false, id: jigHashId, message: 'Lock creation failed' })
        }

        continue
      }

      const req: ChangeJigOwnerRequest = {
        JigId: jigHashId,
        DesiredOwnerEmail: this.desiredOwnerEmail,
        DesiredOwnerTenantID: this.transferToTenantID,
        action: 'transfer_ownership',
      }

      const res = await this.changeJigOwner(req)

      if (res.isSuccessful) {
        this.jigTransferResults.push({
          ...this.changeJigOwnerResult,
          isSuccessful: res,
          id: jigHashId,
          toEmail: this.desiredOwnerEmail,
        })
      } else if (!res.isSuccessful && res.terminate) {
        // Terminate all requests since it's either target Tenant ID incorrect or target Email incorrect
        break
      } else {
        this.jigTransferResults.push({ isSuccessful: res.isSuccessful, id: jigHashId, message: 'Incorrect Jig ID.' })
      }

      await this.releaseLock(this.lockPayload)

      await new Promise((resolve) => setTimeout(() => resolve(true), 1000))
    }

    this.resetTransferData()
    this.isTransfering = false
  }

  private async handleUnlockJig() {
    this.isUnlockingJig = true

    const res = await this.breakLock({ jigId: this.lockedJigHashId })

    if (res.isSuccessful) {
      this.unlockJigResult = {
        isSuccessful: true,
        id: this.lockedJigHashId,
        message: '',
      }
    } else {
      this.unlockJigResult = {
        isSuccessful: false,
        id: this.lockedJigHashId,
        message: res.message,
      }
    }
    this.isUnlockingJig = false
  }
}
